<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'刷卡记录'"
    >
      <div class="search" slot="tool">
        <el-input
          clearable
          placeholder="根据人员名称查询"
          v-model="searchQuery.personName"
          class="searchInput"
        >
          <template slot="prepend">人员名称</template>
        </el-input>
        <div>
          <div class="el-input-group">
            <div class="el-input-group__prepend">日期</div>
            <el-date-picker
              v-model="dateArr"
              @change="changeDate"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/swingCardRecord",
      searchVal: "",
      jobList: [],
      searchQuery: { personName: "", swingTimeStart: "", swingTimeEnd: "" },
      dateArr: [],
      props: [
        {
          label: "人员名称",
          prop: "personName",
          align: "center",
          formatter(row) {
            return row.personName || "陌生人";
          },
        },

        {
          label: "卡号",
          prop: "cardNumber",
          align: "center",
        },
        {
          label: "人员编号",
          prop: "personCode",
          align: "center",
        },
        {
          label: "部门",
          prop: "deptName",
          align: "center",
        },
        {
          label: "门通道名称",
          prop: "acsChannelName",
          align: "center",
        },
        {
          label: "开门方式",
          prop: "openType",
          align: "center",
        },
        {
          label: "开门结果",
          prop: "openResult",
          align: "center",
        },
        {
          label: "事件类型",
          prop: "enterOrExit",
          align: "center",
        },
        {
          label: "时间",
          prop: "swingTime",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "产品编号",
          prop: "number",
          type: "input",
        },

        {
          label: "蓝牙ID",
          prop: "deviceId",
          type: "input",
        },
      ],
    };
  },
  created() {
    // this.getJobList();
  },
  methods: {
    changeDate(e) {
      this.searchQuery.swingTimeStart = e ? e[0] : "";
      this.searchQuery.swingTimeEnd = e ? e[1] : "";
    },
    async getJobList() {
      await api.dictionary.getAllList({ type: "商品分类" }).then((res) => {
        this.formProps[5].selectData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  .el-input-group {
    /deep/ .el-input__inner {
      display: flex;
    }
  }
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
